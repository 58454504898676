@switch (data.type || "routerLink") {
    @case ("routerLink") {
        <a
            [attr.id]="data.id !== undefined ? data.id : null"
            [style.width]="data.width"
            [style.min-width]="data.minWidth"
            class="btn {{ buttonStyle || null }}"
            [routerLink]="[data.link]"
        >
            @if (data.imagePath !== undefined) {
                <div class="image-btn">
                    <div class="sv-button-image">
                        <img
                            [ngSrc]="
                                modalGralPipe.transform(
                                    data.imagePath,
                                    'img-empty'
                                )
                            "
                            alt="Image"
                            fill
                        />
                    </div>
                </div>
            }
            {{ data.title }}</a
        >
    }
    @case ("backLink") {
        <a
            [attr.id]="data.id !== undefined ? data.id : null"
            [style.min-width]="data.minWidth"
            [style.width]="data.width"
            class="btn {{ buttonStyle || null }}"
            href="#"
            (click)="goBack()"
        >
            @if (data.imagePath !== undefined) {
                <div class="image-btn">
                    <div class="sv-button-image">
                        <img
                            [ngSrc]="
                                modalGralPipe.transform(
                                    data.imagePath,
                                    'img-empty'
                                )
                            "
                            alt="Image"
                            fill
                        />
                    </div>
                </div>
            }
            {{ data.title }}</a
        >
    }
    @case ("emit") {
        <div
            [attr.id]="data.id !== undefined ? data.id : null"
            [style.min-width]="data.minWidth"
            [style.width]="data.width"
            class="btn {{ buttonStyle || null }}"
            (click)="notify.emit()"
        >
            @if (data.imagePath !== undefined) {
                <div class="image-btn">
                    <div class="sv-button-image">
                        <img
                            [ngSrc]="
                                modalGralPipe.transform(
                                    data.imagePath,
                                    'img-empty'
                                )
                            "
                            alt="Image"
                            fill
                        />
                    </div>
                </div>
            }
            {{ data.title }}
        </div>
    }
    @case ("emitRouter") {
        <a
            [attr.id]="data.id !== undefined ? data.id : null"
            [style.min-width]="data.minWidth"
            [style.width]="data.width"
            class="btn {{ buttonStyle || null }}"
            (click)="notify.emit()"
            [routerLink]="[data.link]"
        >
            @if (data.imagePath !== undefined) {
                <div class="sv-button-image">
                    <img
                        [ngSrc]="
                            modalGralPipe.transform(data.imagePath, 'img-empty')
                        "
                        alt="Image"
                        fill
                    />
                </div>
            }
            {{ data.title }}</a
        >
    }
    @case ("submit") {
        <button
            [attr.id]="data.id !== undefined ? data.id : null"
            [style.min-width]="data.minWidth"
            [style.width]="data.width"
            type="submit"
            class="btn {{ buttonStyle || null }}"
        >
            @if (data.imagePath !== undefined) {
                <div class="image-btn">
                    <div class="sv-button-image">
                        <img
                            [ngSrc]="
                                modalGralPipe.transform(
                                    data.imagePath,
                                    'img-empty'
                                )
                            "
                            alt="Image"
                            fill
                        />
                    </div>
                </div>
            }

            {{ data.title }}
        </button>
    }
    @default {
        <a
            [attr.id]="data.id !== undefined ? data.id : null"
            [style.min-width]="data.minWidth"
            [style.width]="data.width"
            class="btn {{ buttonStyle || null }}"
            href="{{ data.link }}"
        >
            @if (data.imagePath !== undefined) {
                <div class="image-btn">
                    <div class="sv-button-image">
                        <img
                            [ngSrc]="
                                modalGralPipe.transform(
                                    data.imagePath,
                                    'img-empty'
                                )
                            "
                            alt="Image"
                            fill
                        />
                    </div>
                </div>
            }
            {{ data.title }}</a
        >
    }
}
